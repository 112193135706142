import {api} from '@/store';
import {IVisit} from "@/interfaces/visit";
import {FormData} from "@/interfaces/form-config";
export default class Visit{
    constructor(public visit?:IVisit) {}

    static async addObserver(form:FormData):Promise<any>{
        return await api.post('api/visit/add-observer',form);
    }

    static async cancel(form:FormData):Promise<any>{
        return await api.post('api/visit/cancel',form);
    }

    static async complete(form:FormData):Promise<any>{
        return await api.post('api/'+form.type+'/complete',form);
    }

    static async confirm(form:FormData):Promise<any>{
        return await api.post('api/visit/confirm',form);
    }

    static async generateRescheduleLink(form:FormData):Promise<any>{
        return await api.post('api/visit/generate-reschedule',form);
    }

    static async get(form:FormData):Promise<any>{
        return await api.post('api/visit/'+form.visit_id,form);
    }

    static async getFinancialData(form:FormData):Promise<any>{
        return await api.post('api/financial/overview',form);
    }

    static async getFinancialReport(form:FormData):Promise<any>{
        return await api.post('api/financial/report',form);
    }

    static async getObserverUsers(form:FormData):Promise<any>{
        return await api.post('api/visit/get-observer-users',form);
    }

    static async getParticipantList(form:FormData):Promise<any>{
        return await api.post('api/reports/participants',form);
    }

    static async getRecording(visit_id:Number, form:any):Promise<any>{
        return await api.post('api/visit/' + visit_id + '/recording',form);
    }

    static async getReportData(form:any):Promise<any>{
        return await api.post('api/reports/visit/overview',form);
    }

    static async getTransferUsers(form:FormData):Promise<any>{
        return await api.post('api/visit/get-transfer-users',form);
    }

    static async getUserVisits(form:any):Promise<any>{
        return await api.post('api/visit/get-user-visits',form);
    }

    static async join(form:FormData):Promise<any>{
        return await api.post('api/meeting/join/'+form.type,form);
    }

    static async learnerTechIssue(form:FormData):Promise<any>{
        return await api.post('api/visit/learnertech',form);
    }

    static async noShow(form:FormData):Promise<any>{
        return await api.post('api/visit/noshow',form);
    }

    static async removeObserver(form:FormData):Promise<any>{
        return await api.post('api/visit/remove-observer',form);
    }

    static async reschedule(form:FormData):Promise<any>{
        return await api.post('api/visit/reschedule',form);
    }

    static async shareScenario(form:FormData):Promise<any>{
        return await api.post('api/visit/share',form);
    }

    static async transfer(form:FormData):Promise<any>{
        return await api.post('api/visit/transfer',form);
    }

    static async updateDashboard(form:FormData):Promise<any>{
        return await api.post('api/visit/update-dashboard',form);
    }
}
