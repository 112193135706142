import { createRouter, createWebHashHistory, RouterView } from 'vue-router'
import { store } from '@/store';
import { ActionTypes } from '@/store/modules/auth';
import { MutationTypes } from '@/store/modules/auth/mutations';


//App Components
import Dashboard from '../views/Dashboard.vue';
import Error from '../views/Error.vue';

//auth components
import ForgotPassword from '../views/ForgotPassword.vue';
import Login from '../views/Login.vue';
import Profile from '../views/Profile.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Passcode from '../views/Passcode.vue';

//Management
import Articles from '../views/management/Articles.vue';
import Management from '../views/management/Seats.vue';
import PreApproval from '../views/management/preApproved.vue';
import UserManagement from '../views/management/Users.vue';
import ManageUser from '../views/management/User.vue';
import ClientRequest from '../views/management/ClientRequest.vue';
import ClientRequests from '../views/management/ClientRequests.vue';
import SchedulingDates from '../views/management/SchedulingDates.vue';
import SocialMedia from '../views/management/SocialMedia.vue';
import RegistrationFields from '../views/management/RegistrationFields.vue';
import WelcomeMessages from '../views/management/WelcomeMessages.vue';
import Institutions from '../views/management/Institutions.vue';
import Passwords from '../views/management/Passwords.vue';
import Quotes from '../views/management/Quotes.vue';
import Quote from '../views/management/Quote.vue';
import Agreement from '../views/Agreement.vue';
import Agreements from '../views/Agreements.vue';
import PartnerConfiguration from '../views/management/PartnerConfiguration.vue';
import LandingPages from '../views/management/LandingPages.vue';
import InvoiceRequests from '../views/management/InvoiceRequests.vue';
import InvoiceRequest from '../views/management/InvoiceRequest.vue';

//Playlists
import Playlists from '../views/playlist/playlists.vue';
import MyPlaylist from '../views/playlist/my-playlist.vue';
import Playlist from '../views/playlist/playlist.vue';

//Scenarios
import Scenarios from '../views/scenarios/Scenarios.vue';
import ScenarioOverview from '../views/scenarios/scenario-overview.vue';
import ScenarioShare from '../views/scenarios/scenario-share.vue';
import PrintScenario from '../views/scenarios/print-scenario.vue';
import PrintFeedback from '../views/scenarios/print-feedback.vue';
import ZoomMessage from '../views/scenarios/zoom-message.vue';

//Rubrics
import Rubrics from '../views/rubrics/Rubrics.vue';
import Rubric from '../views/rubrics/Rubric.vue';

//Recruitment
import Recruits from '../views/recruitment/Recruits.vue';
import Recruit from '../views/recruitment/Recruit.vue';

// import NotificationCenter from "@/views/NotificationCenter.vue";

//Request
import Request from '../views/requests/Wrapper.vue';
import Scenario from '../views/scenarios/Scenario.vue';

//Events
import Interview from '@/views/events/Interview.vue';
import TrainingSession from '@/views/events/TrainingSession.vue';
import Visit from '@/views/events/Visit.vue';

//Scorecards
import Scorecard from "@/views/Scorecard.vue";

//Mobile Verification
import MobileVerification from '@/views/MobileVerification.vue';

//Reports
import FinancialReport from '@/views/reports/financial.vue';

//Training Practive
import FeedbackPractice from '@/views/training/feedback-practive.vue';

//Footer Links
import ContactUs from '../views/ContactUs.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsOfService from '../views/TermsOfService.vue';


const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { layout: "empty", title:'Login' },
    },
    {
        path: "/forgot-password",
        name: "forgot.password",
        component: ForgotPassword,
        meta: { layout: "empty", title:'Forgot Password'},
    },
    {
        path: "/reset-password/:token",
        name: "reset.password",
        component: ResetPassword,
        meta: { layout: "empty" ,title:"Reset Password"},
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {title:"Dashboard",disableBreadcrumbs:true},
    },
    {
        path: "/scenarios",
        component: RouterView,
        name: 'scenarios',
        meta: {title:"Scenarios"},
        children:[
            {
                path:"",
                name: "scenario.list",
                meta: {title:"List"},
                component: Scenarios,
            },
            {
                path:":id",
                name: "scenario.show",
                component: Scenario,
            },
            {
                path:":id/overview",
                name: "scenario.overview",
                component: ScenarioOverview,
            },
            {
                path: ":id/share",
                name: "scenario.share",
                component: ScenarioShare,
                meta: { layout: "empty" },
            },
            {
                path: ":id/print",
                name: "scenario.print",
                component: PrintScenario,
                meta: { layout: "empty" },
            },
            {
                path: ":id/print-feedback",
                name: "feedback.print",
                component: PrintFeedback,
                meta: { layout: "empty" }
            },
            {
                path: ":id/zoom-message",
                name: "scenario.zoom-message",
                component: ZoomMessage,
                meta: { layout: "empty" },
            }
        ]
    },
    {
        path: "/rubrics",
        component: RouterView,
        name: 'rubrics',
        meta: {title:"Rubrics"},
        children:[
            {
                path:"",
                name: "rubric.list",
                component: Rubrics,
                meta: {title:"List"},
            },
            {
                path: ":id",
                name: "rubric.show",
                component: Rubric,
            }
        ]
    },
    {
        path: "/recruitment",
        name: "recruitment",
        meta: {title:"Recruitment"},
        component: RouterView,
        children: [
            {
                path: "pending",
                name: "recruitment.list",
                meta: {title:"List"},
                component: Recruits
            },
            {
                path: "user/:user_id",
                name: "recruitment.pending",
                meta: {title:"Pending User"},
                component: Recruit
            }
        ]
    },
    {
        path: "/management",
        name: "management",
        meta: {title:"Management"},
        component: RouterView,
        children: [
            {
                path: "articles",
                name: "management.articles",
                meta: {title:"Article Posts"},
                component: Articles
            },
            {
                path: "clients",
                name: "management.clients",
                meta: {title:"Client Admin Applications"},
                component: ClientRequests
            },
            {
                path: "client/:request_id",
                name: "management.client",
                meta: {Title:"Client Admin Application"},
                component: ClientRequest
            },
            {
                path: "dates",
                name: "management.scheduling-dates",
                meta: {title:"Partner Scheduling dates"},
                component: SchedulingDates
            },
            {
                path: "institutions",
                name: "management.institutions",
                meta: {title:"Manage Institutions"},
                component: Institutions
            },
            {
                path: "passwords",
                name: "management.passwords",
                meta: {title:"Manage Passwords"},
                component: Passwords
            },
            {
                path: "pre-approved",
                name: "management.pre-approved",
                meta: {title:"Pre Approved"},
                component: PreApproval
            },
            {
                path: "seats",
                name: "management.seats",
                meta: {title:"Seats"},
                component: Management
            },
            {
                path: "users",
                name: "management.users",
                meta: {title:"Users"},
                component: UserManagement
            },
            {
                path: "user/:user_id",
                name: "management.user",
                meta: {title:"User"},
                component: ManageUser
            },
            {
                path: "registration-fields",
                name: "management.registration-fields",
                meta: {Title:"Partner Registration Fields"},
                component: RegistrationFields
            },
            {
                path: "social-media",
                name: "management.social-media",
                meta: {title:"Social Media Posts"},
                component: SocialMedia
            },
            {
                path: "welcome-messages",
                name: "management.welcome-messages",
                meta: {title:"Welcome Messages"},
                component: WelcomeMessages
            },
            {
                path: "quotes",
                name: "management.quotes",
                meta: {Title:"Quote Requests"},
                component: Quotes
            },
            {
                path: "quote/:request_id",
                name: "management.quote",
                meta: {title:"Quote Request"},
                component: Quote
            },
            {
                path: "partner/:partner_id",
                name: "management.partner",
                meta: {title: "Landing Page Configuration"},
                component: PartnerConfiguration
            },
            {
                path: "partners",
                name: "management.partners",
                meta: {title: "Landing Pages"},
                component: LandingPages
            },
            {
                path: "invoices",
                name: "management.invoices",
                meta: {title: "Invoices"},
                component: InvoiceRequests
            },
            {
                path: "invoice/:invoice_id",
                name: "management.invoice",
                meta: {title: "Invoice"},
                component: InvoiceRequest
            }
        ]
    },
    // {
    //     path: '/notifications',
    //     name: "notifications",
    //     meta: {title:"Notification Center"},
    //     component: NotificationCenter,
    //     //meta: {permission:'route.profile'}
    // },
    {
        path: "/passcode",
        name: "passcode",
        meta: {title:"Passcode Entry"},
        component: Passcode
    },
    {
        path: "/playlists",
        name: "playlists",
        meta: {title:"Playlists"},
        component: RouterView,
        children: [
            {
                path: "",
                name: "playlists.list",
                meta: {disableBreadcrumbs:true},
                component: Playlists
            },
            {
                path: ":id",
                name: "playlists.item",
                meta: {disableBreadcrumbs:true},
                component: Playlist
            }
        ]
    },
    {
        path: '/profile',
        name: "profile",
        meta: {title:"My Profile"},
        component: Profile,
        //meta: {permission:'route.profile'}
    },
    {
        path: '/my-playlists',
        name: "my-playlists",
        meta: {title:"My Playlists"},
        component: MyPlaylist,
        //meta: {permission:'route.profile'}
    },
    {
        path: '/request/:request_id',
        name: 'request',
        meta: {title:"Request"},
        component: Request
    },
    {
        path: '/interview/:interview_id',
        name: 'interview',
        meta: {title:'Interview'},
        component: Interview
    },
    {
        path: '/training/:training_session_id',
        name: 'training',
        meta: {title:'Training'},
        component: TrainingSession,
    },
    {
        path: '/training/:training_session_id/practice/feedback',
        name: 'training.practice.feedback',
        component: FeedbackPractice
    },
    {
        path: '/visit/:visit_id',
        name: 'visit',
        meta: {title:'Visit'},
        component: Visit
    },
    {
        path: "/visit/:visit_id/scorecard",
        name: "visit.scorecard",
        meta: {title:"Scorecard"},
        component: Scorecard
    },
    {
        path: '/verification',
        name: 'verification',
        meta: {title:'Mobile Verification'},
        component: MobileVerification
    },
    {
        path: '/reports/financial',
        name: 'financial.report',
        meta: {title:'Financial Report'},
        component: FinancialReport
    },
    {
        path: '/contactus',
        name: "contactus",
        meta: {title:"Contact Us"},
        component: ContactUs,
    },
    {
        path: '/privacy-policy',
        name: "PrivacyPolicy",
        meta: {title:"Privacy Policy"},
        component: PrivacyPolicy,
    },
    {
        path: '/terms-of-service',
        name: "TermsOfService",
        meta: {title:"Terms of Service"},
        component: TermsOfService,
    },
    {
        path: '/agreement/:agreement_id',
        name: 'PartnerAgreement',
        meta: {title: 'Partner Agreement'},
        component: Agreement
    },
    {
        path: '/agreements',
        name: 'PartnerAgreements',
        meta: {title: 'Partner Agreements'},
        component: Agreements
    },
    {
        path: '/error/:code',
        name: 'error',
        component: Error,
    },
    { path: '/', redirect: { name: 'dashboard' } }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});

//todo interceptors

router.beforeEach(async (to, from, next) => {
    let toRoute;

    //check if you are already authenticated unless you are going to reset password
    if((!store.getters.authenticated && to.name != 'login') && to.name != 'forgot.password' && to.name != 'reset.password'){

        //check if session is authenticated, via other app or closing tab and returning
        await store.dispatch(ActionTypes.check);

        store.commit(MutationTypes.setError,false);
        //if session not good and not headed to login, set route to login page
        if(!store.getters.authenticated && to.name != 'login') toRoute = { name: 'login' };
        //if session good and is login page set page to dashboard
        if(store.getters.authenticated && to.name == 'login') toRoute = { name: 'dashboard' };
    }
    const safe_urls = ['login','forgot.password','reset.password','passcode'];
    if(!store.getters.passwordApproved && typeof(to.name) == 'string' && !safe_urls.includes(to.name)){
        toRoute = { name: 'passcode' };
    }

    if(to.name == 'login'){
        await store.dispatch(ActionTypes.check);
        store.commit(MutationTypes.setError,false);
        if(store.getters.authenticated) toRoute = { name: 'dashboard'};
    }

    //check permissions
    if(to.meta){
        if(to.meta.permission){
            if(!store.getters.permissions.includes(to.meta.permission))  toRoute = {name:'error',params:{code:'403'}};
        }
    }

    if(toRoute) next(toRoute);
    else next();
})

export default router
