<template lang="html">
    <div v-if="!loading && !loading_error" class="d-flex flex-column">
        <div v-if="scenario" class="row no-gutters" :key="scenario?.data.status">
            <div class="col-12">
                <div class="text-center scenario-title">{{scenario?.data.title}} <span class="text-success">({{ScenarioStatus[scenario?.data?.status]}})</span></div>
            </div>
            <div class="col-12 mb-4">
                <div class="button-navigation">
                    <button v-if="((scenario?.data.status === ScenarioStatus.DRAFT || scenario?.data.status === ScenarioStatus.EDITING) && !userIsReviewer)" type="submit" @click="review" class="btn btn-primary">Submit For Review</button>

                    <button v-if="(scenario?.data.status === ScenarioStatus.REVIEW) && (userIsReviewer || store.getters.hasPermission('scenario.admin'))" type="submit" @click="editing" class="btn btn-primary">Send Back for Editing</button>

                    <button v-if="(scenario?.data.status === ScenarioStatus.REVIEW) && (userIsReviewer || store.getters.hasPermission('scenario.admin'))" type="submit" @click="approve" class="btn btn-primary">Approve</button>

                    <button v-if="canTake" type="submit" @click="take" class="btn btn-primary">Take Scenario Under My Review</button>

                    <button v-if="(scenario?.data.status === ScenarioStatus.APPROVED && (isHeadTrainer || store.getters.hasPermission('scenario.admin')))" type="submit" @click="publish" class="btn btn-primary">Go Live with this Scenario</button>

                    <button v-if="((scenario?.data.status === ScenarioStatus.APPROVED || scenario?.data.status === ScenarioStatus.PUBLISHED) && store.getters.user.props?.super_admin && !makeLiveEdits && selectedTab == 'Scenario')" type="submit" @click="adminEdits" class="btn btn-primary">Make Scenario Changes</button>
                    <button v-else-if="((scenario?.data.status === ScenarioStatus.APPROVED || scenario?.data.status === ScenarioStatus.PUBLISHED) && store.getters.user.props?.super_admin && !makeLiveEdits && selectedTab != 'Scenario')" type="submit" class="btn btn-secondary disabled">Make Scenario Changes</button>

                    <button v-if="((scenario?.data.status === ScenarioStatus.APPROVED || scenario?.data.status === ScenarioStatus.PUBLISHED) && store.getters.user.props?.super_admin && makeLiveEdits && selectedTab == 'Scenario')" type="submit" @click="adminEditsDone" class="btn btn-primary">Done with Changes</button>
                    <button v-else-if="((scenario?.data.status === ScenarioStatus.APPROVED || scenario?.data.status === ScenarioStatus.PUBLISHED) && store.getters.user.props?.super_admin && makeLiveEdits && selectedTab != 'Scenario')" type="submit" class="btn btn-secondary" disabled>Done with Changes</button>

                    <button v-if="store.getters.hasPermission('scenario.clone')" type="submit" @click="cloneScenario" class="btn btn-primary">Clone Scenario</button>
                </div>
            </div>
        </div>
        <div v-else class="row no-gutters">
            <div class="col-12">
                <div class="text-center scenario-title"> You do not have permission to access this scenario <span class="text-success">(No Access)</span></div>
                <div class="text-center"><a href="/#/dashboard">Return to My Dashboard >></a></div>
            </div>
        </div>

        <div v-if="scenario" id="scenario-authoring">
            <div class="row no-gutters">
                    <div class="col-md-9">
                        <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-6 col-sm-12 text-center mb-4" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active" @changed="tabChanged">

                            <tab prefix="<h2 class='mt-2 scenario-tabs'>" name="Scenario">
                                <div class="card-columns mx-4">
                                    <scenario-field-group @click="selectedField = 'overview'" label="Overview" :scenario="scenario" :enable-comments="enableComments" :show-comment-bubble="showCommnetBubble">
                                        <scenario-field type="image-select" v-model="scenario.data.patient_image.location" field="patient_image" :required="true" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="text" v-model="scenario.data.title" field="title" :required="true" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="text" v-model="scenario.data.patient_name" field="patient_name" :required="true" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="radio" v-model="scenario.data.televisit" field="televisit" :required="false" :items="[{'label':'Yes', 'value':true},{'label':'No', 'value':false}]" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="editor" v-model="scenario.data.objective" field="objective" :required="true" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="text" v-model="scenario.data.target_audience" field="target_audience" :required="true" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="text" v-model="scenario.data.summary" field="summary" :required="true" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                        <scenario-field type="select" v-model="scenario.data.session_duration" field="session_duration" :required="true" :items="[{'label':'30 mins','value':30},{'label':'60 mins','value':60}]" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits" v-on:save="fieldUpdate"/>
                                    </scenario-field-group>
                                    <scenario-field-group v-for="(item,index) in fields" @click="selectedField = toSnakeCase(item.label)" :label="item.label" :description="item.description" :selected="toSnakeCase(item.label) === selectedField " v-bind:key="index" :enable-comments="enableComments" :show-comment-bubble="showCommnetBubble" :scenario="scenario">
                                        <template v-slot>
                                            <scenario-dynamic-field v-for="(field, findex) in item.fields" :disable-label="item.fields.length === 1"  v-bind:key="findex" :scenario="scenario" :field="field" :can-edit="(userIsAuthor && enableForm) || makeLiveEdits"/>
                                        </template>
                                    </scenario-field-group>
                                </div>
                            </tab>

                            <tab prefix="<h2 class='mt-2 scenario-tabs'>" name="Observer Scorecard">
                                <div class="m-4" @click="selectedField = 'observer_scorecard'">
                                    <observer-scorecard :scenario="scenario" field="observer_scorecard" label="Observer Scorecard" :enable-comments="enableComments" :show-comment-bubble="showCommnetBubble"></observer-scorecard>
                                </div>
                            </tab>

                            <tab prefix="<h2 class='mt-2 scenario-tabs'>" name="SP Scorecard">
                                <div class="m-4" @click="selectedField = 'sp_scorecard'">
                                    <standardized-patient-scorecard :scenario="scenario" field="sp_scorecard" label="SP Scorecard" :enable-comments="enableComments" :show-comment-bubble="showCommnetBubble"></standardized-patient-scorecard>
                                </div>
                            </tab>

                            <tab prefix="<h2 class='mt-2 scenario-tabs'>" name="Participant Feedback">
                                <div class="m-4" @click="selectedField = 'participant_feedback'">
                                    <scenario-participant-feedback :scenario="scenario" field="participant_feedback" label="Participant Feedback" :enable-comments="enableComments" :show-comment-bubble="showCommnetBubble"></scenario-participant-feedback>
                                </div>
                            </tab>

                        </tabs>
                    </div>

                    <div v-if="scenario?.data.status !== ScenarioStatus.DRAFT" class="col-md-3 mt-3">
                        <!--comments-->
                        <scenario-notes v-if="scenario?.data.status === ScenarioStatus.DRAFT || scenario?.data.status === ScenarioStatus.REVIEW || scenario?.data.status === ScenarioStatus.EDITING" static-view :scenario="scenario" :field="selectedField" :enable-commenting="enableComments"></scenario-notes>

                        <tabs v-else :options="{ useUrlFragment: false }" nav-class="nav nav-pills scenario-settings" nav-item-class="nav-item text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
                            <tab name="Details" v-if="!isTrainer">
                                <div class="scenario-field-header with-elements bg-secondary text-white">
                                    Details
                                </div>
                                <div class="card-body mb-2">
                                    <ul class="list-group list-group-flush">
                                        <li class="d-flex justify-content-between align-items-center">
                                            <div class="text-muted">Created By:</div>
                                            <div><a href="javascript:void(0)">{{ scenario?.data.author.name }}</a></div>
                                        </li>
                                        <li v-if="scenario?.data.reviewer !== null" class="d-flex justify-content-between align-items-center">
                                            <div class="text-muted">Reviewed By:</div>
                                            <div><a href="javascript:void(0)">{{ scenario?.data.reviewer.name }}</a></div>
                                        </li>
                                        <scenario-field type="editor" v-model="scenario.data.funding_text" field="funding_text" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/>
                                        <scenario-field type="select" v-model="scenario.data.accreditation_Id" field="accreditation_Id" label_override="Accreditation" :items="[{'label':'No Accreditation', 'value':''}]" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/>
                                        <scenario-field type="money" v-model="scenario.data.giftcard_amount" field="giftcard_amount" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/>
                                        <scenario-field type="select" v-model="scenario.data.giftcard_campaign_id" field="giftcard_campaign_id" label_override="Giftcard Campaign" :items="giftcard_options" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/>
                                        <scenario-field type="select" v-model="scenario.data.enable_giftcard" field="enable_giftcard" label_override="Enable Giftcards" :items="[{'label':'Yes', 'value':1},{'label':'No', 'value':0}]" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/>
                                        <scenario-field type="tags" v-model="scenario.data.tags" field="tags" :items="tags" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/>
                                        <!-- <scenario-field type="select" v-if="store.getters.partner && store.getters.partner.is_sim" v-model="scenario.data.institution_id" field="institution_id" label_override="Institution" :items="institutions" :can-edit="canConfig" list-group-item v-on:save="fieldUpdate"/> -->
                                        <li class="d-flex justify-content-between align-items-center mt-4">
                                            <div class="text-muted">Created On:</div>
                                            <div>{{moment(scenario?.data.created_at).format('M/D/Y')}}</div>
                                        </li>
                                        <li class="d-flex justify-content-between align-items-center">
                                            <div class="text-muted">Last Update:</div>
                                            <div>{{moment(scenario?.data.updated_at).format('M/D/Y')}}</div>
                                        </li>
                                    </ul>
                                </div>

                                <scenario-select-trainer :can-edit="canConfig" v-if="scenario" :scenario="scenario"></scenario-select-trainer>
                            </tab>

                            <tab name="Comments" v-if="!isTrainer && !isHeadTrainer">
                                <scenario-notes class-style="card-body"  static-view :scenario="scenario" :field="selectedField" :enable-commenting="enableComments"></scenario-notes>
                            </tab>

                            <tab name="Distribution" v-if="!isTrainer && !isHeadTrainer">
                                <scenario-partner-distribution :can-edit="canConfig" v-if="scenario" :scenario="scenario" />
                            </tab>

                            <tab name="SP">
                                <scenario-user-assignment :can-edit="canConfig" v-if="scenario" :scenario="scenario"></scenario-user-assignment>
                            </tab>

                            <tab name="Observers">
                                <scenario-user-assignment :can-edit="canConfig" v-if="scenario" :scenario="scenario" observer></scenario-user-assignment>
                            </tab>

                            <tab name="Scorecard Display" v-if="!isTrainer">
                                <scorecard-display-settings :can-edit="canConfig" v-if="scenario" :scenario="scenario" />
                            </tab>
                        </tabs>
                    </div>
            </div>
        </div>
    </div>
    <div v-if="!loading && loading_error">
        <h3>Error Loading Scenario</h3>
        <p>{{loading_error_str}}</p>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>
<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import {IScenario} from "@/interfaces/scenario";
    import {IScenarioFieldGroup} from "@/interfaces/scenario-field-group";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {ScenarioStatus} from "@/enums/scenario-status";
    import {store} from "@/store";
    import {Tabs, Tab} from 'vue3-tabs-component';
    import {toSnakeCase} from "@/utilities/string-helpers";
    import {toTitleCase} from '@/utilities/string-helpers';
    import {UserRole} from "@/enums/user-role";
    import GiftCard from "@/models/gift-card";
    /* import Institution from "@/models/institution"; */
    import Loading from 'vue3-loading-overlay';
    import moment from "moment";
    import ObserverScorecard from "@/components/scenarios/observer-scorecard.vue";
    import router from "@/router";
    import Scenario from "@/models/scenario";
    import ScenarioNotes from "@/components/scenarios/scenario-notes.vue";
    import StandardizedPatientScorecard from "@/components/scenarios/standardized-patient-scorecard.vue";
    import ScenarioParticipantFeedback from "@/components/scenarios/scenario-participant-feedback.vue";
    import ScenarioSelectTrainer from "@/components/scenarios/scenario-select-trainer.vue";
    import ScenarioPartnerDistribution from "@/components/scenarios/scenario-partner-distribution.vue";
    import ScenarioUserAssignment from "@/components/scenarios/scenario-user-assignment.vue";
    import ScenarioField from "@/components/scenarios/scenario-field.vue";
    import ScorecardDisplaySettings from "@/components/scenarios/scorecard-display-settings.vue";
    import ScenarioFieldGroup from "@/components/scenarios/scenario-field-group.vue";
    import ScenarioDynamicField from "@/components/scenarios/scenario-dynamic-field.vue";
    import Swal from 'sweetalert2';
    export default defineComponent( {
        components: {
            Loading,
            ObserverScorecard,
            ScenarioDynamicField,
            StandardizedPatientScorecard,
            ScenarioFieldGroup,
            ScenarioField,
            ScenarioParticipantFeedback,
            ScenarioNotes,
            ScenarioPartnerDistribution,
            ScenarioSelectTrainer,
            ScenarioUserAssignment,
            ScorecardDisplaySettings,
            Tab,
            Tabs
        },
        name:'Scenario',
        setup(){
            const giftcard_options = ref<Array<any>>([]);
            const loading = ref(false);
            const makeLiveEdits = ref(false);
            const scenario = ref<Scenario | undefined>();
            const scenarioId = ref<number | undefined>(router.currentRoute.value.params.id ? parseInt(router.currentRoute.value.params.id as string) : undefined);
            const fields = ref<Array<IScenarioFieldGroup>>([]);
            const selectedField = ref('');
            const selectedTab = ref('');
            const tags = ref([]);
            /* const institutions = ref<Array<any>>([]); */
            const loading_error = ref<Boolean>(false);
            const loading_error_str = ref<String>("");

            //permissions
            const userIsReviewer = computed(()=>scenario.value?.data?.reviewer_user_id === store.getters.user.props.id && !userIsAuthor.value);
            const userIsAuthor = computed(()=>scenario.value?.data?.author_user_id === store.getters.user.props.id);
            const canTake = computed(()=>(scenario.value?.data?.status === ScenarioStatus.REVIEW) && store.getters.hasPermission('scenario.review') && !scenario.value?.data?.reviewer_user_id );
            const canConfig = computed(()=>(scenario.value?.data?.status === ScenarioStatus.APPROVED || scenario.value?.data?.status === ScenarioStatus.PUBLISHED) && store.getters.hasPermission('scenario.admin'));
            const enableForm = computed(()=>scenario.value?.data?.status  === ScenarioStatus.DRAFT || scenario.value?.data?.status === ScenarioStatus.EDITING);
            const enableComments = computed(()=>{
                  if(scenario.value?.data?.status === ScenarioStatus.DRAFT || scenario.value?.data?.status === ScenarioStatus.REVIEW || scenario.value?.data?.status === ScenarioStatus.EDITING){
                    if (store.getters.hasPermission('scenario.review') && userIsReviewer.value) return true;
                    if (scenario.value?.data?.status === ScenarioStatus.EDITING && userIsAuthor.value) return  true;
                  }
                  return false;
            });
            onMounted(()=>{
                if(scenarioId.value !== undefined) load();
            });

            const isHeadTrainer = computed(()=>{
                let headTrainer = store.getters.hasRole(UserRole.HEADTRAINER)
                return headTrainer;
            });

            const isTrainer = computed(()=>{
                let trainer = store.getters.hasRole(UserRole.TRAINER)
                return trainer;
            });

            const showCommnetBubble = computed(()=>{
                let showBubble = (isTrainer.value) ? false : true;
                return showBubble;
            });

            //actions
            const approve = async ()=> {
                await save(ScenarioStatus.APPROVED,'Scenario has been approved.')
            }

            const cancel = ()=> router.push({path:'/scenarios/'+scenarioId.value});


            const adminEdits = async()=>{
                makeLiveEdits.value = true;
            }

            const adminEditsDone = async()=>{
                makeLiveEdits.value = false;
            }

            const cloneScenario = async() => {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'Are you sure you want to clone this scenario?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then(async (res) => {
                    if(res.value){
                        let post_data = {
                            'scenario_id': scenarioId.value
                        };
                        await Scenario.clone(post_data).then((res) => {
                            store.commit(MutationTypes.addSystemNotification, {title:'Scenario cloned successfully.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                            router.push({ path: '/scenarios'});
                            setTimeout(() => { router.push({ path: '/scenarios/' + res.data.scenario_id }) });
                        },(err)=>{
                            store.commit(MutationTypes.addSystemNotification, {title:'Error cloning scenario.',content:err.response.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                        });
                    } else if(res.dismiss === Swal.DismissReason.cancel){
                        Swal.close();
                    }
                });
            }

            const editing = async ()=> {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'No additional comments can be made when sending back to the Author.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then(async (result) => {
                    if (result.value) {
                        await save(ScenarioStatus.EDITING,'Scenario has been sent for edits.')
                        Swal.close();
                    } else if (result.dismiss ===  Swal.DismissReason.cancel) {
                       Swal.close();
                    }
                })
            }

            const fieldUpdate = async (data:any)=>{
              loading.value = true;
              await scenario.value?.saveField(data.field, data.value).then((res:any)=> {
                  if(data.field ==='tags'){
                      scenario.value?.setField(data.field, res.data);
                  }
              });
              loading.value = false;
            }

            const load = async()=>{
                loading.value = true;
                await Scenario.getFields(scenario.value?.data.id).then((res:any)=>{
                    fields.value = res.data.map((i:any)=>i as IScenarioFieldGroup);
                });
                await Scenario.get(scenarioId.value).then((res:any)=>{
                    let data:IScenario = res.data.scenario;
                    if (data) {
                        scenario.value = new Scenario(data);
                        // store.commit(MutationTypes.setCurrentPageTitle,scenario.value?.data?.title);
                        Scenario.getTagOptions(res.data.scenario.id).then((res)=>{
                            tags.value = res.data.map((i:any)=>JSON.parse(i.name).en);
                        });
                    }
                },(err)=>{
                    loading_error.value = true;
                    loading_error_str.value = err.response.data.message;
                });

                if (scenario.value?.data?.status === ScenarioStatus.APPROVED ||
                    scenario.value?.data?.status === ScenarioStatus.PUBLISHED) {
                    await GiftCard.getCampaigns().then((res) => {
                        giftcard_options.value[0] = {};
                        giftcard_options.value[0]['label'] = '';
                        giftcard_options.value[0]['value'] = null;
                        let index = 1;
                        res.data?.giftcard_options.forEach((item:any) => {
                            giftcard_options.value[index] = {};
                            giftcard_options.value[index]['label'] = item['campaign_name'];
                            giftcard_options.value[index]['value'] = item['campaign_id'];
                            index++;
                        });
                    },(err)=>{
                        loading_error.value = true;
                        loading_error_str.value = err.response.data.message;
                    });
                    /* await Institution.list().then((res)=>{
                        institutions.value[0] = {};
                        institutions.value[0]['label'] = '';
                        institutions.value[0]['value'] = null;
                        let index = 1;
                        res.data?.institutions.forEach((item:any) => {
                            institutions.value[index] = {};
                            institutions.value[index]['label'] = item['name'];
                            institutions.value[index]['value'] = item['id'];
                            index++;
                        });
                    }); */
                }

                loading.value = false;
            }

            const publish = async()=> {
                Swal.fire({
                    title: 'Going Live. Are you sure?',
                    text: 'You are releasing this scenario to the public.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then(async (result) => {
                    //Let's check some values
                    let passed_validation = true;
                    let message = '';

                    // let standardized_patient = scenario.value?.data?.users.filter((i:any)=>{
                    //     return i.pivot.role_id === UserRole.SP;
                    // });

                    // if (!standardized_patient?.length) {
                    //     passed_validation = false;
                    //     message = 'No SPs have been assigned!<br/>';
                    // }

                    if (!scenario.value?.data?.partners.length && !scenario.value?.data?.is_sim) {
                        passed_validation = false;
                        message += 'No Partners have been selected!';
                    }
                    if (passed_validation && result.value) {
                        await save(ScenarioStatus.PUBLISHED,'The scenario has been published and is Live!')
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.close();
                    } else {
                        store.commit(MutationTypes.addSystemNotification, {title:'Missing Data Warning',content:message,icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                    }
                })
            }

            const review = async ()=> {
                let validation = '';
                if (scenario.value?.getStatus() === ScenarioStatus.DRAFT || scenario.value?.getStatus() === ScenarioStatus.EDITING) {
                    if(!scenario.value?.data?.patient_name) {
                        validation = "<strong>Patient Name</strong> is a required field<br/>";
                    }
                    if(!scenario.value?.data?.objective) {
                        validation = validation + "<strong>Objective</strong> is a required field<br/>";
                    }
                    if(!scenario.value?.data?.target_audience) {
                        validation = validation + "<strong>Target Audience</strong> is a required field";
                    }
                    if (validation.length > 0) {
                        //https://sweetalert2.github.io/#examples
                        Swal.fire({
                            title: '<strong>Required fields are empty</strong>',
                            html: validation,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Acknowledged',
                            width: 350,
                        });
                    } else {
                        Swal.fire({
                            title: 'Are you sure?',
                            text: 'No changes can be made while in review.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No'
                        }).then(async (result) => {
                            if (result.value) {
                                await save(ScenarioStatus.REVIEW,'Scenario has been sent for review!')
                                Swal.close();
                                // For more information
                                // https://sweetalert2.github.io/#examples
                                // https://sweetalert2.github.io/#handling-dismissals
                            } else if (result.dismiss ===  Swal.DismissReason.cancel) {
                                Swal.close();
                            }
                        })
                    }
                } else if (scenario.value?.getStatus() === ScenarioStatus.APPROVED) {
                    //add rules here
                }
            }

            const save = async(status:ScenarioStatus,message:string)=>{
                loading.value = true;
                await scenario.value?.saveField('status',status).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:message,icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    if (scenario.value?.data.status) scenario.value.data.status = status;
                    cancel();
                 });
                loading.value = false;
            }

            const tabChanged = async(chosenTab:any)=>{
                selectedTab.value = chosenTab.tab.name;
            }

            const take = async()=>{
                loading.value = true;
                await Scenario.take(scenarioId.value).then((res:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:"You can now leave comments.",icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                     let data:IScenario = res.data;
                    scenario.value = new Scenario(data);
                },(err:any)=>{
                    console.error(err);
                });
                loading.value = false;
            }

            return {
                adminEdits,
                adminEditsDone,
                approve,
                cancel,
                canTake,
                canConfig,
                cloneScenario,
                editing,
                enableComments,
                enableForm,
                fields,
                fieldUpdate,
                giftcard_options,
                // institutions,
                isHeadTrainer,
                isTrainer,
                loading,
                loading_error,
                loading_error_str,
                makeLiveEdits,
                moment,
                publish,
                review,
                selectedField,
                selectedTab,
                tabChanged,
                showCommnetBubble,
                scenario,
                ScenarioStatus,
                store,
                tags,
                toTitleCase,
                toSnakeCase,
                take,
                userIsReviewer,
                userIsAuthor
            }
        }
    });
</script>
<style lang="scss">
    @import "src/assets/styles/pages/chat";
    @import "src/assets/styles/theme-paper";
    @import "sweetalert2/dist/sweetalert2";
    .preview-area{
        height: calc(100vh - 270px);
        overflow-y: auto;
    }
</style>
